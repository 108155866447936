/* .dice {
    width: 37.3px;
    height: 37.3px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #2b283a;
    font-size: 21.5px;
    background: white;
    cursor: pointer;
} */
.dice {
    display: grid;
    grid-template-areas: 
        'a . c'
        'e g f'
        'd . b';

    flex: 0 0 auto;
    width: 42px;
    height: 42px;
    border-radius: 4px;
    cursor: pointer;
    padding: 7px;
    box-shadow: inset 0 3.5px white, 
                inset 0 -3.5px #bbb, 
                inset 3.5px 0 #d7d7d7,
                inset -3.5px 0 #d7d7d7;
}
.pip {
    display: block;
    align-self: center;
    justify-self: center;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #333;
    box-shadow: inset 0 2px #111, inset 0 -2px #555;
}
.nopips {
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.7;
}
.pip:nth-child(2) {
    grid-area: b;
}
.pip:nth-child(3) {
    grid-area: c;
}
.pip:nth-child(4) {
    grid-area: d;
}
.pip:nth-child(5) {
    grid-area: e;
}
.pip:nth-child(6) {
    grid-area: f;
}
/* This selects the last pip of odd-valued dice (1, 3, 5) and positions the pip in the center */
.pip:nth-child(odd):last-child {
    grid-area: g;
}